import React, { useState } from "react";
import { graphql } from "gatsby";
import * as b from "../components/bootstrap.module.css";
import Thumbnail from "../components/thumbnail";
import BlogContent from "../components/blog-content";
import Posts from "../components/posts";
import Comments from "../components/comments";
import Translate from "../components/translate"
import Breadcrumbs from "../components/breadcrumbs";
import useMedia from "../hooks/useMedia";
import Out from "../components/out-product";
import Icon from "../components/icon.tsx";
import Layout from "../components/layout";
import CommentsForm from "../components/comments-form";

import BaseHead from '../components/base-head';
import siteMetdata from "../utils/site-metadata";
import Faq from "../components/faq";

export const Head = ({...props}) => (
  <BaseHead {...props} title={`${props?.data?.mysqlBlog?.title} | akredo.pl`} description={props?.data?.mysqlBlog?.intro}>
    {props?.pageContext?.mysqlId && <meta property="og:image" content={`${siteMetdata.domain}/social-media/blog/${props.pageContext.mysqlId}.png`} /> }
  </BaseHead>
)

const BlogPromotion = ({ 
  data: { mysqlBlog, allMysqlBlog, allMysqlFaq, allMysqlComments }, 
  pageContext: { breadcrumbs },
  location: { pathname } 
}) => {
  const {
    title,
    mysqlImages,
    intro,
    content,
    promo_bonus,
    is_promo_end,
    image_description,
    promo_for,
    promo_end,
    mysqlId,
    template,
  } = mysqlBlog;
  const isMobileResolution = useMedia(['(max-width: 992px)'], [true], false);

  const [isOpenForm, setIsOpenForm] = useState(false);
  const toggleForm = () => setIsOpenForm(!isOpenForm);
  const [isFaq, setIsFaq] = useState(false);
  const toggleFaq = (value) => setIsFaq(value ?? !isFaq)

  return (
    <Layout pathname={pathname}>
      <article className="blog-page">
        <div className={["blog-promotions"].join(' ')}>
          <div className={[b.container,'breadcrumbs-mobile-2',b.py3,b.ptLg5,b.positionRelative,'breadcrumbs-zIndex',"scroll-inner"].join(' ')}>
            <Breadcrumbs items={breadcrumbs} textWhite />
          </div>
          <div className={[b.container,b.ptLg3,b.pbLg5,b.py3].join(' ')}>
            <div className={[b.row,b.justifyContentBetween,b.alignItemsCenter, b.pt3].join(' ')}>
              <div className={b.colLg}>
                <span className="big-span">Bonus</span>
                <p>{image_description || promo_bonus}</p>
              </div>
              <div className={b.colLgAuto}>
                <div className="bank-list-products-box">
                  {is_promo_end === 1 && <div className={[b.px3, b.pt3].join(' ')}>
                    <div className={["firm-row-likes",b.dFlex,b.justifyContentCenter,b.alignItemsCenter].join(' ')}>
                      <Icon size={16} color="#B93838" icon="Info" className={['iconToWhite', b.mr1].join(' ')} />
                      <span className={['redToWhite', 'font-size-13',b.my0,'tp-name'].join(' ')} style={{ lineHeight: 0 }}>Promocja dobiegła końca</span>
                    </div>
                  </div>}

                  <div className={[b.p3, b.pxLg5, b.pbLg5, is_promo_end ? b.ptLg3 : b.ptLg5].join(' ')}>
                    <Thumbnail image={mysqlImages[0]}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="blog-promotions-text">
          <div className={[b.container, b.py4].join(" ")}>
            <div className={[b.dFlex,b.justifyContentBetween,b.mb3].join(' ')}>
              <h1 className={["big-title", b.mb3].join(" ")}>{title}</h1>
              {!isMobileResolution&&(<Out className={[b.btn,"btn-success","btn-cta",b.my0,b.fontWeightNormal,"button-256","blog-promotions-out"].join(" ")} item={{ url: mysqlBlog.term_normalized }} defaultDescription="Take advantage of the promotion" defaultIcon="Logout"/>) }
            </div>
            <p className={["blog-lead", !isMobileResolution && b.mb0].join(' ')}>{intro}</p>
            {isMobileResolution&&(<Out className={[b.btn,"btn-success","btn-cta",b.my0,b.fontWeightNormal,"blog-promotions-out"].join(" ")} item={{ url: mysqlBlog.term_normalized }} defaultDescription="Take advantage of the promotion" defaultIcon="Logout"/>) }
          </div>
        </div>
        <div className={b.container}>
          <div className={["blog-content", b.row, b.mtLg3,'blueToGray', 'dark-li'].join(" ")}>
            <div className={` ${template !== 'calculator' ? b.colXl8 : b.colLg12 } dark-theme-listing`}>
              {content && <BlogContent content={content} />}
                {allMysqlFaq?.nodes.length > 0 && (
                  <>
                    <div className="post-content">
                      <h2>
                        <Translate tKey="Often asked questions"/>
                      </h2>

                      {allMysqlFaq.nodes.map(({ title, text }, i) => <Faq title={title} text={text} key={i} />)}
                  
                      <div className={[b.dFlex, b.justifyContentCenter].join(" ")}>
                        <button className={[b.btn,"btn-light","btn-cta","button-256",].join(" ")}  onClick={() => { toggleForm();toggleFaq(true); }}>
                          <Icon size={20} icon="Question-mark" className={[b.mr1, "icon-blog"].join(" ")}/>
                          <Translate tKey="Ask your own question"/>
                        </button>
                      </div>
                    </div>
                  </>
                )}
            </div>
            <div className={[b.colLg4,b.ptLg5,b.pt3].join(' ')}> 
              <div className={["bank-list-products-box",b.p3].join(' ')}>
                <span className={[b.pt0,'title','font-size-20'].join(' ')}>Szczegóły promocji</span>
                <div className={[b.row,b.mt3].join(' ')}>
                  <div className={[b.col3].join(' ')}>
                    <small>Bank</small>
                  </div>
                  <div className={[b.col9,"blog-promotions-info-box",b.px0].join(' ')}>
                    <Thumbnail image={mysqlImages[0]}/>
                  </div>
                </div>
                <div className={[b.row,b.mt3].join(' ')}>
                  <div className={[b.col3].join(' ')}>
                    <small>Termin</small>
                  </div>
                  <div className={[b.col9].join(' ')}>
                    <p className={b.mt0}>{promo_end || 'do odwołania'}</p>
                  </div>
                </div>
                <div className={[b.row,b.mt3].join(' ')}>
                  <div className={[b.col3].join(' ')}>
                    <small>Bonus</small>
                  </div>
                  <div className={[b.col9].join(' ')}>
                    <p className={b.mt0}>{promo_bonus}</p>
                  </div>
                </div>
                <div className={[b.row,b.mt3].join(' ')}>
                  <div className={[b.col3].join(' ')}>
                    <small>Dla kogo</small>
                  </div>
                  <div className={[b.col9].join(' ')}>
                    <p className={b.mt0}>{promo_for}</p>
                  </div>
                </div>
                <Out className={[b.btn,"btn-success","btn-cta",b.mt3,b.mb0,b.fontWeightNormal,].join(" ")} item={{ url: mysqlBlog.term_normalized }} defaultDescription="Take advantage of the promotion" defaultIcon="Logout"/>
              </div>
            </div>
          </div>

          <Comments items={allMysqlComments.nodes} title="comments" formID={mysqlId} commentType="entry"/>
          <CommentsForm isOpen={isOpenForm} close={() => { toggleForm(false);toggleFaq(false); }} formID={mysqlId} commentType="entry" faq={isFaq} />

          {allMysqlBlog.nodes.length > 0 && <aside className={[b.mb5, b.pbLg5].join(" ")}>
            <h2 className={["title", b.dBlock, b.mb4,b.mt3].join(' ')}><Translate tKey="Read also"/>:</h2>
            <Posts items={allMysqlBlog.nodes} bankpromo />
          </aside>}
        </div>
      </article>
    </Layout>
  );
};

export default BlogPromotion;

export const query = graphql`
  query BlogPromotion($mysqlId: Int!, $parentPromoUrl: [String!], $terms_path: String!) {
    mysqlBlog(promo_bonus: {ne: " "}, status: { eq: "publish" }, mysqlId: { eq: $mysqlId }) {
      intro
      title
      promo_bonus
      promo_for
      promo_end(formatString: "DD.MM.YYYY")
      is_promo_end
      url
      image_description
      terms_path
      term_normalized
      mysqlImages {
        childImageSharp {
          gatsbyImageData(width:226)
        }
      }
      description
      content
    }
    allMysqlBlog(
      filter: {
        status: {eq: "publish"}, 
        promo_bonus: {ne: ""},
        terms_path: {ne: $terms_path},
        blogTerms: {elemMatch: {path: {in: $parentPromoUrl}}}
        is_promo_end: { ne: 1 }
      }, 
      limit: 4
    ) {
      nodes {
        title
        description
        image_description
        url
        terms_path
        term_normalized
        mysqlImages {
          childImageSharp {
            gatsbyImageData(width: 70)
          }
        }
      }
    }
    allMysqlComments(filter: {type: {eq: "entry"}, item: {eq: $mysqlId}}, sort: {order: DESC, fields: up}) {
      nodes {
        ...CommentData 
      }
    }
    allMysqlFaq(
      filter: { item: { eq: $mysqlId }, type: { eq: "article" }, answer: { ne: null } },
      sort: { fields: priority, order: DESC }
    ) {
      nodes {
        title: question
        text: answer
      }
    }
  }
`;
